import classNames from 'classnames';
import { useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Logo from 'components/icons/Logo';
import TempoLogo from 'components/icons/TempoLogo';
import { TEMPO } from 'context/BrandContext';
import useBrand from 'hooks/useBrand';
import style from 'styles/components/messageCenter/Promotion';
import carouselStyle from 'styles/components/messageCenter/PromotionCarousel';

const Promotion = ({
  actionFunc = () => {},
  actionText = '',
  containerStyle = '',
  description = '',
  dismissable = true,
  heading = '',
  isCarousel = false,
  photo = '',
  shown = true,
  subHeading,
  url = '',
  onDismiss,
}) => {
  const isTempo = useBrand(TEMPO);
  const transitionRef = useRef();

  const renderAction = () => {
    if (url) {
      return (
        <a
          href={url}
          target={url.includes('homechef.com') ? '' : '_blank'}
          rel="noopener"
          className="btn btnSm btnPrimary mr-4"
        >
          {actionText}
        </a>
      );
    } else {
      return (
        <button className="btn btnSm btnPrimary mr-4 mt-4" onClick={actionFunc}>
          {actionText}
        </button>
      );
    }
  };

  return (
    <TransitionGroup
      className={classNames({
        [carouselStyle.wrapper]: isCarousel,
        'mx-0': isCarousel,
      })}
    >
      {shown && (
        <CSSTransition
          timeout={400}
          classNames={{
            enter: 'fadeInOut-enter scaleHeight-enter',
            enterActive: 'fadeInOut-enter-active scaleHeight-enter-active',
            exit: 'fadeInOut-exit scaleHeight-exit',
            exitActive: 'fadeInOut-exit-active scaleHeight-exit-active',
          }}
          nodeRef={transitionRef}
        >
          <aside
            className={classNames(containerStyle, {
              [style.grid]: !isCarousel,
              [style.container]: !isCarousel,
              [carouselStyle.container]: isCarousel,
              [carouselStyle.instertitialContainer]: isCarousel,
            })}
            ref={transitionRef}
          >
            <div
              className={classNames({
                [style.photosContainer]: !isCarousel,
                [carouselStyle.photosContainer]: isCarousel,
              })}
            >
              <div
                className={classNames('flex items-center', {
                  [carouselStyle.carouselContainer]: isCarousel,
                })}
              >
                <div
                  className={classNames({
                    [style.photo]: !isCarousel,
                    [carouselStyle.photoWrapper]: isCarousel,
                    'flex items-center justify-center': !photo,
                  })}
                >
                  {photo ? (
                    <React.Fragment>
                      {isCarousel ? (
                        <div
                          className={carouselStyle.photo}
                          style={{ backgroundImage: `url(${photo})` }}
                        ></div>
                      ) : (
                        <img src={photo} alt="Home Chef Partner Logo" className={style.photo} />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {isTempo ? (
                        <TempoLogo className="h-15 w-20" />
                      ) : (
                        <Logo className="h-15 w-20" />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames({
                [style.content]: !isCarousel,
                [carouselStyle.content]: isCarousel,
                'p-5 bp1:px-8 bp1:py-6': isCarousel,
              })}
            >
              <h1 className="h4 text-primary max-w-2xl pb-1">
                {heading}
                {subHeading ? <br /> : null}
                {subHeading ? subHeading : null}
              </h1>
              <p className="text text-secondary max-w-2xl pb-6">{description}</p>
              {actionText && renderAction()}
              {dismissable && (
                <button onClick={onDismiss} className="btn btnSm btnSecondary">
                  Dismiss
                </button>
              )}
            </div>
          </aside>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

Promotion.propTypes = {
  actionText: PropTypes.string,
  actionFunc: PropTypes.func,
  containerStyle: PropTypes.string,
  description: PropTypes.string,
  dismissable: PropTypes.bool,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  photo: PropTypes.string,
  url: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
  shown: PropTypes.bool,
  isCarousel: PropTypes.bool,
};

export default Promotion;
